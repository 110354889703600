import BasePage from '../../../../../components/table_page';
import Form from './form';
import Relation from './form/relation.vue';

export default {
  extends: BasePage,
  data() {
    return {};
  },
  components: {
    Form,
    Relation,
  },
  created() {
    this.getConfigList('tpm_budget_fine');
  },
  methods: {
    modalClick({ val, row }) {
      const { code, name } = val;
      if (code === 'add' || code === 'edit' || code === 'view') {
        this.formConfig = {
          code,
          row,
        };
        this.modalConfig.title = name || '查看';
        this.openFull();
      } else if (code === 'associated_activity_categories') {
        this.formName = 'Relation';
        this.formConfig = {
          code,
          row,
        };
        this.modalConfig.title = '关联的活动大类';
        this.modalConfig.width = '60%';
        this.openDrawer();
      }
    },
  },
};
