import BasePage from '../../../../../../components/table_page';

export default {
  extends: BasePage,
  props: {
    paramsProps: Object,
  },
  data() {
    return {};
  },
  components: {},
  created() {
    this.getConfigList('tpm_budget_relation_cost_type');
  },
  methods: {},
};
