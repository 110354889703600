import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

import { CheckRules } from '../../../../components';

formCreate.component('CheckRules', CheckRules);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'tpm_budget_fine_form',
    };
  },
  methods: {
    setRule(item) {
      let v = item;
      if (v.field === 'auditRequireList') {
        v.props = {
          ...v.props,
          title: '核销资料维护',
          configs: {
            url: '/tpm/tpmAuditCollectExampleController/list',
            label: 'exampleName',
            value: 'exampleCode',
            params: { exampleType: 'audit', enableStatus: '009' },
          },
        };
        v.value = [];
      } else if (v.field === 'collectRequireList') {
        v.props = {
          ...v.props,
          title: '材料采集维护',
          configs: {
            url: '/tpm/tpmAuditCollectExampleController/list',
            label: 'exampleName',
            value: 'exampleCode',
            params: { exampleType: 'collect', enableStatus: '009' },
          },
        };
        v.value = [];
      } else if (v.field === 'extraAuditRatio') {
        v = {
          ...v,
          validate: [
            {
              pattern: /^(?:[1-9]\d*|0)(?:\.\d{1,2})?$/,
              message: '请填写大于0的数字，最多两位小数',
              trigger: 'blur',
            },
          ],
        };
      } else if (v.field === 'auditValidity') {
        v = {
          ...v,
          validate: [
            {
              pattern: /^\+?[1-9][0-9]*$/,
              message: '请填写大于零的整数',
              trigger: 'blur',
            },
          ],
        };
      } else if (v.field === 'costFormCode') {
        v.restful = '/mdm/mdmfunctionsub/sub_list';
        v.optionsKey = { label: 'functionName', value: 'functionCode' };
        v.restfulParams = {
          functionCode: 'act-detail-template',
        };
      } else if (v.field === 'auditFormCode') {
        v.restful = '/mdm/mdmfunctionsub/sub_list';
        v.optionsKey = { label: 'functionName', value: 'functionCode' };
        v.restfulParams = {
          functionCode: 'audit-detail-template',
        };
      }

      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request
          .get('/tpm/tpmCostTypeFineController/query', { id: this.formConfig.row.id })
          .then((res) => {
            if (res.success) {
              const data = res.result;

              data.payTypeList = JSON.parse(data.payTypeList);

              // 核销资料维护
              data.auditRequireList = JSON.parse(data.auditRequireList) || [];
              // 材料采集维护
              data.collectRequireList = JSON.parse(data.collectRequireList) || [];

              this.setValue(data);
            }
          });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData();

      if (formData) {
        let url = '/tpm/tpmCostTypeFineController/save';
        const params = { ...formData };

        if (this.formConfig.code === 'edit') {
          url = '/tpm/tpmCostTypeFineController/update';
          params.id = this.formConfig.row.id;
        }

        if (params.payTypeList instanceof Array && params.payTypeList.length > 0) {
          params.payTypeList = JSON.stringify(params.payTypeList);
        } else {
          params.payTypeList = null;
        }

        // 材料采集维护
        params.collectRequireList = params.collectRequireList.length > 0 ? JSON.stringify(params.collectRequireList) : null;
        // 核销资料维护
        params.auditRequireList = params.auditRequireList.length > 0 ? JSON.stringify(params.auditRequireList) : null;

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
